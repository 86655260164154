/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3">
            <h1 className="title">Ultimate Trending Bot</h1>
          </Col>
          <Col md="3">
            <h3 className="title">The One Way to Find True Investors</h3>
          </Col>
          <Col md="3">
            <h3 className="title">Refund Guarantee!</h3>
          </Col>

          <Col md="3">
            <h3 className="title">Get in touch:</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://t.me/trending_pinksale"
                id="tooltip622135962"
                target="_blank"
              >
                <i className="fab fa-telegram" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://t.me/ultimate_pinksale"
                id="tooltip230450801"
                target="_blank"
              >
                <i className="fab fa-telegram" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                Contact us
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
