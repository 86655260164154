import React from "react";
import bot from "../../assets/img/bott.png";
// reactstrap components
import { Container } from "reactstrap";

export default function PageHeader() {
  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand">
          <img
            src={bot}
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "50%",
              marginBottom: 20,
            }}
            alt="Bot Image"
          />
          <h1 className="h1-seo">Ultimate Trending Bot</h1> <br />
          <h4 className="d-none d-sm-block">
            Get in on the boost now! When you see a project take instantly top
            #1 #2, they are boosted by our bot!
          </h4>
        </div>
      </Container>
    </div>
  );
}
