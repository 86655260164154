/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

export default function Download() {
  return (
    <div
      className="section section-download"
      data-background-color="black"
      id="download-section"
    >
      <img alt="..." className="path" src={require("assets/img/path1.png")} />
      <Container>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <h2 className="title">How does it work?</h2>
            <h4 className="description">
              Our unique bot utilizes the Tor Proxy and Tor browser, eliminating
              the need for additional proxy expenses. For PinkSale Trending, you
              won't require any badges, as all algorithms are integrated
              directly into the bot. It connects directly to trending APIs and
              other DEX sites.
              <br />
              Everything you need for a boost!
            </h4>
          </Col>
          <Col className="text-center" lg="8" md="12">
            <h2 className="title">Our Refund Policy</h2>
            <h4 className="description">
              We guarantee that our bot will propel your project to the top 5
              trending list. However, if your project fails to achieve this
              result, we offer a full refund of your payment.
              <br />
              Everything you need for a trust!
            </h4>
          </Col>
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn-round"
              color="info"
              href="https://www.t.me/ultimate_pinksale"
              role="button"
              size="lg"
            >
              Get Boosted
            </Button>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row className="row-grid align-items-center my-md">
          <Col lg="6">
            <h3 className="text-info font-weight-light mb-2">
              Thank you for supporting us!
            </h3>
            <h4 className="mb-0 font-weight-light">
              Let's get in touch on any of these platforms.
            </h4>
          </Col>
          <Col className="text-lg-center btn-wrapper" lg="6">
            <Button
              className="btn-icon btn-round"
              color="twitter"
              id="twitter"
              size="lg"
              href="https://t.me/trending_pinksale"
            >
              <i className="fab fa-telegram" />
            </Button>
            <UncontrolledTooltip delay={0} target="twitter">
              Subscribe!
            </UncontrolledTooltip>

            <Button
              className="btn-icon btn-round"
              color="github"
              href="https://github.com/UltimateTrending"
              id="tooltip877922017"
              size="lg"
              target="_blank"
            >
              <i className="fab fa-github" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip877922017">
              Follow us on Github
            </UncontrolledTooltip>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
