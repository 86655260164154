/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function Examples() {
  return (
    <div className="section section-examples" data-background-color="black">
      <img alt="..." className="path" src={require("assets/img/path1.png")} />
      <div className="space-50" />
      <Container className="text-center">
        <Row>
          <Col sm="6">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/botbannerfull.png")}
            />
            <Button className="btn-simple btn-round" color="primary">
              Full Version
            </Button>
          </Col>
          <Col sm="6">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/ultimate.jpg")}
            />
            <Button className="btn-simple btn-round" color="primary">
              PinkSale Version
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="text-center">
        <Row>
          <Col sm="6">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/botprof.jpg")}
            />
          </Col>
          <Col sm="6">
            <img
              alt="..."
              className="img-raised"
              src={require("assets/img/botproff2.jpg")}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
